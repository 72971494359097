import "./RankingDetail.css";
import logo from "../../Image/logoteam.png";
import search from "../../Image/Search.png";
import no1 from "../../Image/no1.png";
import no2 from "../../Image/no2.png";
import no3 from "../../Image/no3.png";
import { useRef, useState } from "react";
import { uid } from "uid";
import * as XLSX from 'xlsx';
import { Avatar, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { InfoPlayer } from "../info-player";
import { createImageUrl } from "../../servicers/api-get-img";
import LazyLoad from "react-lazyload";
import LoadingImage from "../img-loading";
import { object } from "yup";

// typeProps = {
//   list : array ,
//   type : listTeam || null
// }

export const RankingDetail = (props) => {
  const { list, type } = props;
  const [keyWord, setKeyWord] = useState("");
  const itemRef = useRef(null);
  const [modalInfo, setModalInfo] = useState(false);
  const [dataInfo, setDataInfo] = useState(null);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setKeyWord(searchTerm);
    const filtered = list.filter((item) =>
      item.id.toLowerCase().includes(searchTerm.toLowerCase())
    );

    //Sroll to item
    if (e.key === "Enter") {
      if (filtered.length > 0) {
        const element = itemRef.current;
        if (element) {
          const yOffset = -100;
          const y = element.offsetTop + yOffset;
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(list);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "data.xlsx");
  };

  const handleMovePlayerInfo = (data, index) => {
    // navigate("/info-player", { state: { ...data } });
    setDataInfo({ ...data, rank: index + 1 });
    setModalInfo(true);
  };
  const [loading, setLoading] = useState(true);

  return (
    <>
      <div className="table-ranking">
        <div className="tab-ranking">
          <div className="search">
            <div className="search-group">
              <input
                className="search-input"
                // maxLength={4}
                placeholder="Search ID"
                value={keyWord}
                onChange={handleSearch}
                onKeyDown={handleSearch}
              />
            </div>
          </div>
          <p
            style={{ alignItems: "center", justifyContent: "center" }}
            className="name"
          >
            Name
          </p>
          <p
            style={{ textAlign: "center", justifyContent: "center" }}
            className="team"
          >
            {type == "listTeam" ? "Category" : "Team"}
          </p>
          <p className="age">Age</p>
          <p className="point">Point</p>
          {type !== "listTeam" && <p className="rank">Rank</p>}
        </div>
        <div style={{ height: 600, overflow: "scroll" }}>
          {type !== "listTeam" && list.length > 0 && (
            <div className="top-player-ranking-detail">
              {list[1] !== undefined && (
                <div
                  onClick={() => handleMovePlayerInfo(list[1], 1)}
                  className="box-top-player animation2 "
                >
                  {list[1]?.avatar !== null ? (
                    <LoadingImage
                      src={createImageUrl(list[1]?.avatar)}
                      alt={`Image`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <LoadingImage
                      src={`https://api.dicebear.com/7.x/miniavs/svg?seed=$`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <div className="box-top-team bgrank2">
                    {list[1]?.logoTeam !== null ? (
                      <LoadingImage
                        src={createImageUrl(list[1]?.logoTeam)}
                        alt={`Image`}
                        style={{
                          width: "50px",
                          height: "30px",
                        }}
                      />
                    ) : (
                      <LoadingImage
                        src={`https://t4.ftcdn.net/jpg/04/72/65/73/360_F_472657366_6kV9ztFQ3OkIuBCkjjL8qPmqnuagktXU.jpg`}
                        style={{
                          width: "50px",
                          height: "30px",
                        }}
                      />
                    )}
                    <a>{list[1]?.name}</a>
                  </div>

                  <div className="box-top-ranking rank2">2</div>
                  <div className="box-top-point bgrank2 ">
                    <a>{list[1]?.point}</a>
                  </div>
                </div>
              )}

              {list[0] !== undefined && (
                <div
                  onClick={() => handleMovePlayerInfo(list[0], 0)}
                  className="box-top-player animation1 mb-30"
                >
                  {list[0]?.avatar !== null ? (
                    <LoadingImage
                      src={createImageUrl(list[0]?.avatar)}
                      alt={`Image`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <LoadingImage
                      src={`https://api.dicebear.com/7.x/miniavs/svg?seed=$`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <div className="box-top-team bgrank1">
                    {list[0]?.logoTeam !== null ? (
                      <LoadingImage
                        src={createImageUrl(list[0]?.logoTeam)}
                        alt={`Image`}
                        style={{
                          width: "50px",
                          height: "30px",
                        }}
                      />
                    ) : (
                      <LoadingImage
                        src={`https://t4.ftcdn.net/jpg/04/72/65/73/360_F_472657366_6kV9ztFQ3OkIuBCkjjL8qPmqnuagktXU.jpg`}
                        style={{
                          width: "50px",
                          height: "30px",
                        }}
                      />
                    )}
                    <a>{list[0]?.name}</a>
                  </div>

                  <div className="box-top-ranking rank1">1</div>
                  <div className="box-top-point bgrank1 ">
                    <a>{list[0]?.point}</a>
                  </div>
                </div>
              )}

              {list[2] !== undefined && (
                <div
                  onClick={() => handleMovePlayerInfo(list[2], 2)}
                  className="box-top-player animation3 mt-48"
                >
                  {list[2]?.avatar !== null ? (
                    <LoadingImage
                      src={createImageUrl(list[2]?.avatar)}
                      alt={`Image`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <LoadingImage
                      src={`https://api.dicebear.com/7.x/miniavs/svg?seed=$`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <div className="box-top-team bgrank3">
                    {list[2]?.logoTeam !== null ? (
                      <LoadingImage
                        src={createImageUrl(list[2]?.logoTeam)}
                        alt={`Image`}
                        style={{
                          width: "50px",
                          height: "30px",
                        }}
                      />
                    ) : (
                      <LoadingImage
                        src={`https://t4.ftcdn.net/jpg/04/72/65/73/360_F_472657366_6kV9ztFQ3OkIuBCkjjL8qPmqnuagktXU.jpg`}
                        style={{
                          width: "50px",
                          height: "30px",
                        }}
                      />
                    )}
                    <a>{list[2]?.name}</a>
                  </div>

                  <div className="box-top-ranking rank3">3</div>
                  <div className="box-top-point bgrank3 ">
                    <a>{list[2]?.point}</a>
                  </div>
                </div>
              )}
            </div>
          )}

          {list.map((item, index) => {
            const topRankingIcon = (index) => {
              if (index == 0) {
                return no1;
              }
              if (index == 1) {
                return no2;
              }
              if (index == 2) {
                return no3;
              }
            };
            if (type !== "listTeam" && (index == 0 || index == 1 || index == 2)) {
              return;
            }
            return (
              <div
                key={index}
                onClick={() => handleMovePlayerInfo(item, index)}
                ref={keyWord === item.id ? itemRef : null}
                className="item-ranking"
                style={{
                  backgroundColor:
                    keyWord == item.id ? "rgb(249, 106, 106)" : "#fff",
                }}
              >
                <div style={{ padding: "0 0px" }} className="search">
                  {item.id}
                </div>
                <div style={{ padding: "0 0px" }} className="name">
                  <div className="avatar-name"> 
                    {item.avatar !== null ? (
                      <LoadingImage
                        key={index}
                        src={createImageUrl(item?.avatar)}
                        alt={`Image ${index}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          // borderTopRightRadius: '40%',
                          // borderBottomLeftRadius: '40%',
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <LoadingImage
                        src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          backgroundColor: "#ccc",
                        }}
                      />
                    )}
                  </div>

                  <p style={{ fontWeight: "700" }}>{item?.name}</p>
                </div>
                <div
                  style={{
                    padding: "0 0px",
                    justifyContent: type == "listTeam" ? "center" : "",
                  }}
                  className="team"
                >
                  {type !== "listTeam" ? (
                    <>
                      <div className="avatar-team">
                        {item.logoTeam !== null ? (
                          <LoadingImage
                            key={index}
                            src={createImageUrl(item?.logoTeam)}
                            alt={`Image ${index}`}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        ) : (
                          <LoadingImage
                            src={`https://t4.ftcdn.net/jpg/04/72/65/73/360_F_472657366_6kV9ztFQ3OkIuBCkjjL8qPmqnuagktXU.jpg`}
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#000",
                            }}
                          />
                        )}
                      </div>
                      <p>{item?.team}</p>
                    </>
                  ) : (
                    <>{item?.category}</>
                  )}
                </div>
                <p className="age">{item?.age}</p>
                <p style={{ color: "rgb(22, 163, 74)" }} className="point">
                  {item?.point}
                </p>
                {type !== "listTeam" && (
                  <p
                    style={{
                      // color : index < 3 ?'red' : '#000',
                      fontWeight: index < 3 ? "900" : "400",
                      fontSize: index < 3 ? "16px" : "14px",
                    }}
                    className="rank"
                  >
                    {index < 3 ? (
                      <img
                        className="top-ranking-icon"
                        src={topRankingIcon(index)}
                      />
                    ) : (
                      index + 1
                    )}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <button className="btn-export" onClick={exportToExcel}>
        Export to Excel
      </button>
      <Modal
        title="INFOMATION"
        centered
        open={modalInfo}
        onCancel={() => setModalInfo(false)}
        footer={null}
        width="auto"
      >
        <InfoPlayer close={setModalInfo} data={dataInfo} />
      </Modal>
    </>
  );
};
